import { formatDateToMD_or_YYYYMD, isBeforeDaysFromToday, isBeforeToday } from '@/utils/date/date';
import { Badge, Flex, Icon, Text } from '@chakra-ui/react';
import { MdCalendarToday } from 'react-icons/md';

const DueDateDisplay = ({ dueDate, isDone }: { dueDate: Date; isDone: boolean }) => {
  const color = isDone
    ? 'transparent'
    : isBeforeToday(dueDate)
      ? 'error'
      : isBeforeDaysFromToday(dueDate, 7)
        ? 'warning'
        : 'transparent';

  return (
    <Badge colorScheme={color} variant={color === 'transparent' ? 'subtle' : 'solid'} minW='5'>
      <Flex alignItems='center'>
        <Icon as={MdCalendarToday} fontSize='sm' />
        <Text fontSize='sm' ml={1}>
          {formatDateToMD_or_YYYYMD(dueDate)}
        </Text>
      </Flex>
    </Badge>
  );
};

export default DueDateDisplay;
