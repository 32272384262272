import { Flex, Icon, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';

const IconWithCount = ({ icon, count }: { icon: IconType; count: number }) => {
  return (
    <Flex alignItems='center' gap={1}>
      <Icon as={icon} color='neutral.500' fontSize='sm' />
      <Text color='neutral.500' fontSize='sm'>
        {count}
      </Text>
    </Flex>
  );
};

export default IconWithCount;
